import SmoothScroll from 'smooth-scroll';


/*-------スムーススクロール ------*/
var scroll = new SmoothScroll('a[href*="#"]', {
    easing: "easeInOutQuad",
    speed: 300,
        updateURL: false,		
});

// スクロール中のキャンセル
var cancelSmoothScroll = () => {
scroll.cancelScroll();
    document.body.removeEventListener('click', cancelSmoothScroll, false);
    document.body.removeEventListener('touchstart', cancelSmoothScroll, false);
    document.body.removeEventListener('wheel', cancelSmoothScroll, false);
}
document.addEventListener('scrollStart', () => {
    document.body.addEventListener('click', cancelSmoothScroll, { once: true } );
    document.body.addEventListener('touchstart', cancelSmoothScroll, { once: true } );
    document.body.addEventListener('wheel', cancelSmoothScroll, { once: true } );
}), false;
document.addEventListener('scrollStop', () => {
    document.body.removeEventListener('click', cancelSmoothScroll, false);
    document.body.removeEventListener('touchstart', cancelSmoothScroll, false);
    document.body.removeEventListener('wheel', cancelSmoothScroll, false);
}, false);